* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: auto;
}

body {
  position: fixed;
  overscroll-behavior-y: none;
  color: black;

  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rdrDateDisplayWrapper {
  background-color: #f5f3f4 !important;
}
